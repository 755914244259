import { useStyles } from '../../../use-styles';
import { PopoverMenuItemThemeValues } from './popover-menu-theme';

export type PopoverMenuDividerProps = {
  color?: PopoverMenuItemThemeValues['dividerColor'];
};
export function PopoverMenuDivider({ color }: PopoverMenuDividerProps) {
  const styles = useStyles('PopoverMenu', 'divider', { color });

  return <div css={styles} />;
}
