import { useCallback, useLayoutEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: number | null, isActive = true) {
  const savedCallback = useRef(callback);
  const intervalId = useRef<ReturnType<typeof setInterval>>();
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const reset = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    if (delay !== null && isActive) {
      intervalId.current = setInterval(() => savedCallback.current(), delay);
    }
  }, [delay, isActive]);

  useLayoutEffect(() => {
    reset();
    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, [reset]);

  return reset;
}
