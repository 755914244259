import React, { useEffect, useReducer, useRef } from 'react';
import { css } from '@emotion/react';
import { useThemeValues } from '../../hooks/use-theme-values';
import { PauseIcon } from '../../icon/icons/pause';
import { PlayIcon } from '../../icon/icons/play';
import { useStyles } from '../../use-styles';
import { IconButton } from '../icon-button/icon-button.component';
import { SpinningLoader } from '../loader/spinning-loader';
import { Text } from '../text';
import { AudioState } from './types';

type AudioScrubberProps = {
  autoplay?: boolean;
  loop?: boolean;
  muted?: boolean;
  onEnded?: () => void;
  onPlay?: () => void;
  preload?: 'auto' | 'metadata' | 'none';
  src: string;
  trackLength?: number;
  trackingId?: string;
  autoSize?: boolean;
  className?: string;
  singlePlayer?: boolean;
  tabIndex?: number;
  showCurrentTime?: boolean;
};

function getTime(time: number) {
  if (!isNaN(time)) {
    return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
  }

  return '0:00';
}

const initialState = {
  isPlaying: false,
  isLoaded: false,
  currentTime: 0,
  duration: 0,
  hasError: false,
  audioEnd: false,
};

const reducer = (state: AudioState, action): AudioState => {
  switch (action.type) {
    case 'isPlaying':
      return { ...state, isPlaying: action.payload };
    case 'currentTime':
      return { ...state, currentTime: action.payload };
    case 'duration':
      return { ...state, duration: action.payload };
    case 'isLoaded':
      return { ...state, isLoaded: action.payload };
    case 'hasError':
      return { ...state, hasError: action.payload };
    case 'audioEnd':
      return { ...state, isPlaying: false, currentTime: 0 };
    case 'reset':
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export const AudioScrubber = ({
  autoplay = false,
  loop = false,
  muted = false,
  onEnded,
  onPlay,
  preload = 'metadata',
  src,
  singlePlayer,
  trackLength = 138,
  trackingId = 'audioScrubber',
  autoSize,
  tabIndex = 0,
  showCurrentTime = false,
  ...rest
}: AudioScrubberProps) => {
  const audioElement = useRef<HTMLAudioElement>(null);
  const hasLoaded = useRef(false); // To prevent multiple calls to onCanPlay
  const [{ isLoaded, isPlaying, currentTime, duration, hasError }, dispatch] = useReducer(reducer, initialState);
  const { spacing } = useThemeValues();
  const onPlayClick = () => {
    if (audioElement && audioElement.current && !isPlaying) {
      dispatch({ type: 'isPlaying', payload: true });
      audioElement.current.play();
    }
  };

  const onPauseClick = () => {
    if (audioElement && audioElement.current && isPlaying) {
      dispatch({ type: 'isPlaying', payload: false });
      audioElement.current.pause();
    }
  };

  const pauseAllAudioPlayersExceptThis: React.ReactEventHandler<HTMLAudioElement> = (e) => {
    const audioPauseButtons = Array.from(document.querySelectorAll('[data-scrubber-pause]')) as HTMLButtonElement[];

    if (audioPauseButtons.length <= 1) return;

    audioPauseButtons.forEach((pauseButton) => {
      const audioElement = pauseButton.closest('[data-audio-scrubber]')?.querySelector('audio');
      if (audioElement != e.currentTarget) {
        pauseButton.click();
      }
    });
  };

  const onAudioEnd = () => {
    setTimeout(() => {
      dispatch({ type: 'audioEnd' });
    }, 100);

    if (onEnded) {
      onEnded();
    }
  };

  const onAudioPlay = (e: React.SyntheticEvent<HTMLAudioElement>) => {
    if (singlePlayer) {
      pauseAllAudioPlayersExceptThis(e);
    }
    if (onPlay) {
      onPlay();
    }
  };

  const onCurrentTimeChange = (e) => {
    const time = +e.target.value;

    if (audioElement && audioElement.current) {
      dispatch({ type: 'currentTime', payload: time });
      audioElement.current.currentTime = time;
    }
  };
  const rangeSliderStyle = useStyles('AudioScrubber', {
    currentTime,
    duration,
    hasError,
  });

  useEffect(() => {
    dispatch({ type: 'reset' });
    if (autoplay) {
      dispatch({ type: 'isPlaying', payload: true });
    }
    hasLoaded.current = false;
  }, [src]);

  const handleAudioLoad = () => {
    if (!hasLoaded.current) {
      dispatch({ type: 'isLoaded', payload: true });
      dispatch({ type: 'hasError', payload: false });
      hasLoaded.current = true;
    }
  };

  return (
    <div
      data-audio-scrubber
      css={
        autoSize
          ? css`
              display: grid;
              grid-template-columns: auto 1fr auto;
              align-items: center;
            `
          : css`
              display: inline-flex;
              align-items: center;
            `
      }
      {...rest}
    >
      {isLoaded ? (
        isPlaying ? (
          <IconButton
            tabIndex={tabIndex}
            data-scrubber-pause
            label='Pause'
            onClick={onPauseClick}
            trackingId={`${trackingId}-btn-pause`}
          >
            <PauseIcon color='default' />
          </IconButton>
        ) : (
          <IconButton
            tabIndex={tabIndex}
            label='Play'
            onClick={onPlayClick}
            disabled={hasError}
            trackingId={`${trackingId}-btn-play`}
          >
            <PlayIcon color='light' />
          </IconButton>
        )
      ) : hasError ? (
        <IconButton tabIndex={tabIndex} label='Play' disabled={true}>
          <PlayIcon color='light' />
        </IconButton>
      ) : (
        <div
          css={css`
            padding: ${spacing(0.5)};
          `}
        >
          <SpinningLoader
            css={css`
              display: block;
            `}
            size='small'
          />
        </div>
      )}
      <div
        css={css`
          align-items: center;
          display: inline-flex;
          height: ${spacing(3)};
          margin: ${spacing(0, 2, 0, 0.5)};
          ${autoSize ? '' : `width: ${trackLength}px;`}
        `}
      >
        <input
          tabIndex={tabIndex}
          css={rangeSliderStyle}
          disabled={hasError}
          onChange={onCurrentTimeChange}
          type='range'
          min='0'
          step='0.000001'
          max={duration}
          value={currentTime}
        />
      </div>
      <Text css={css({ margin: 0, paddingRight: spacing(1) })}>
        {hasError ? 'N/A' : (showCurrentTime ? getTime(currentTime) + '/' : '') + getTime(duration)}
      </Text>
      <audio
        ref={audioElement}
        onCanPlay={handleAudioLoad}
        onLoadedMetadata={handleAudioLoad}
        onPlay={onAudioPlay}
        onEnded={onAudioEnd}
        onError={() => dispatch({ type: 'hasError', payload: true })}
        onLoad={() => dispatch({ type: 'isLoaded', payload: true })}
        onTimeUpdate={(e: any) => dispatch({ type: 'currentTime', payload: e.target.currentTime })}
        onDurationChange={(e: any) => {
          let duration = e.target.duration;
          if (e.target.duration === Infinity) {
            // for some reason for media file with length less than 0 seconds, it's coing as infinity so overrding it to 0
            duration = 0;
          }
          dispatch({ type: 'duration', payload: duration });
        }}
        autoPlay={autoplay}
        loop={loop}
        muted={muted}
        preload={preload}
        src={src}
        css={css`
          max-width: 100%;
        `}
      >
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};
